.services {
	background-color: #1a1919;
	//padding-top: 0;

	@include breakpoint(1100px) {
		font-size: 10px;
	}

	//
	// Top Images
	//
	&-top {
		margin-left: 0;
		margin-right: 0;

		@include breakpoint(1000px) {
			font-size: 7px;
		}

		&-image {
			display: block;
			height: em(385);
			background-repeat: no-repeat;
			background-size: cover;

			&._image-1 {
				background-image: url('../images/services_1.png');
			}

			&._image-2 {
				background-image: url('../images/services_2.png');
			}

			&._image-3 {
				background-image: url('../images/services_3.png');
			}

		}
	}

	.heading-description {
		margin-bottom: em(23);
	}

	&-block {
		//padding-top: em(200);

		@include breakpoint(1100px) {
			//padding-top: em(140);
		}

		h2, h3 {
			color: #ffffff;
		}
	}

	&-list {
		color: #ffffff;

		@include breakpoint(600px) {
			font-size: 15px;
		}

		& + .services-list {
			margin-top: em(50);
		}

		.col {
			width: 30%;
			flex: 0 0 30%;

			@include breakpoint(1000px) {
				width: 45%;
				flex: 0 0 45%;
			}

			@include breakpoint(699px) {
				width: 50%;
				flex: 0 0 50%;
			}

			@include breakpoint(600px) {
				width: 100%;
				flex: 0 0 100%;
				margin-bottom: em(30);
			}
		}
	}

	&-number {
		font-size: em(40);
		line-height: em(28);
		font-weight: 500;
	}

	&-title {
		font-size: em(26);
		line-height: em(22);
		margin-bottom: em(8);

		@include breakpoint(600px) {
			font-size: 6vw;
		}
	}

	&-description {
		font-size: em(18);
		line-height: em(22);

		@include breakpoint(1100px) {
			font-size: 14px;
		}

		@include breakpoint(600px) {
			font-size: em(18);
		}
	}

	&-number,
	&-description {
		opacity: 0.3;
	}

	.btn-container {
		padding-top: em(76);
		//padding-bottom: em(144);

		@include breakpoint(1100px) {
			//padding-bottom: em(80);
		}
	}
}