.main {
	//background: url("../images/main_top.png") 71% 0 no-repeat;
	background-size: 127%;
	height: em(768);
	position: relative;
	padding-top: 4em;

	@include minBreakpoint(3500px) {
		//background-image: url("../images/main_top_5k.png");
	}

	@include breakpoint(1920px) {
		//background-image: url("../images/main_top_1920.jpg");
	}

	@include breakpoint(1750px) {
		background-size: 140%;
	}

	@include breakpoint(1440) {
		background-size: 150%;
	}

	@include breakpoint(1300px) {
		height: 52vw;
		//background-image: url("../images/main_top_1300.jpg");
	}

	@include breakpoint(1200) {
		font-size: 0.95vw;
		background-size: 157%;
	}

	@include breakpoint(1100px) {
		padding-left: 4vw;
		padding-right: 4vw;
		background-size: 157%;
	}

	@include breakpoint(699px) {
		font-size: 1.45vw;
		height: auto;
		background-size: cover;
		background-position: 50% 0;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,0.3);
		z-index: 1;
	}

	&._play-video {

		h1, h2 {
			transform: translate(-200px,0);
			opacity: 0;
		}

		.btn-red {
			transform: translate(0,100px);
			opacity: 0;
		}

		.btn-play {
			//opacity: 0;
		}
	}

	&-video-container {
		position: absolute;
		left: 0;
		top: 0;
		overflow: hidden;
		height: 100%;
		width: 100%;

		video {
			position: absolute;
			left: 50%;
			top: 50%;
			width: 135vw;
			transform: translate(-50%, -50%);
		}
	}

	.container {
		position: relative;
		color: #ffffff;
		z-index: 2;

		@include breakpoint(699px) {
			padding-bottom: em(100);
		}

		@include breakpoint(425px) {
			padding-bottom: 32px;
		}
	}

	h1 {
		font-size: em(80);
		margin-top: em(184,110);
		line-height: em(88,80);
		font-weight: 600;
		margin-bottom: 0.34em;
		transition: all 0.4s ease-out;

		@include breakpoint(480px) {
			padding-right: 14%;
			margin-top: 72px;
		}
	}

	h2 {
		font-size: em(30);
		font-weight: 400;
		color: rgba(255,255,255,0.7);
		line-height: em(40,30);
		margin-bottom: em(80,29);
		transition: all 0.4s ease-out;

		@include breakpoint(699px) {
			font-size: 4vw;
		}

		br {

			@include breakpoint(699px) {
				display: none;
			}
		}
	}

	.landing-logo {
		width: em(123);
		position: absolute;
		right: em(170);
		bottom: em(-75);
		z-index: 2;

		@include breakpoint(699px) {
			display: none;
		}
	}

	.play-button-container {
		position: absolute;
		top: 50%;
		margin-top: em(-36);
		right: 22.2%;
		z-index: 3;
	}

	.btn-play {
		display: block;
		width: em(70);
		height: em(70);
		border-radius: 50%;
		background-color: rgba(255,255,255,0.7);
		position: relative;
		@include flexbox();
		@include flex_util(middle,center);
		transition: all 0.2s ease-out;

		@include breakpoint(699px) {
			display: none;
		}

		&:hover {
			background-color: rgba(255,255,255,1);

			&:before {
				animation: pulse 0.8s infinite;
			}
		}

		&._loading {
			opacity: 0.3 !important;

			&:before {
				animation: pulse 0.8s infinite;
			}
		}

		&._playing {
			opacity: 0.1;

			&:hover {
				opacity: 0.3;
			}

			&:after {
				content: '';
				display: inline-block;
				width: em(12);
				height: em(18);
				border-left: em(4) solid #000;
				border-right: em(4) solid #000;
				border-top: none;
				border-bottom: none;
				margin-left: 0;
			}
		}

		&._pause {

			&:after {
				content: '';
				display: inline-block;
				width: 0;
				height: 0;
				border-left: em(13) solid #000;
				border-top: em(10) solid transparent;
				border-bottom: em(10) solid transparent;
				margin-left: em(5);
			}
		}

		@keyframes pulse {
			0% { transform: scale(1); }
			50% { transform: scale(1.1); }
			100% { transform: scale(1); }
		}

		&:before {
			content: '';
			display: block;
			width: em(112);
			height: em(112);
			position: absolute;
			left: em(-21);
			top: em(-21);
			border: em(2) solid rgba(255,255,255,0.2);
			border-radius: 50%;
		}
	}

	.play-video-mobile {
		display: none;
		text-align: center;
		margin-bottom: 24px;

		@include breakpoint(699px) {
			display: block;
		}
	}

	.btn-play-mobile {
		position: relative;
		font-size: 13px;
		line-height: 16px;
		font-weight: 500;
		color: #ffffff;
		display: inline-block;


		&:before {
			content: '';
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background-color: rgba(255,255,255,0.7);
			position: absolute;
			left: -20px;
		}

		&:after {
			content: '';
			display: inline-block;
			width: 0;
			height: 0;
			border-left: 5px solid #000;
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			position: absolute;
			left: -12px;
			top: 12px;
		}
	}

	.btn-red {
		transition: all 0.4s ease-out;

		@include breakpoint(1000px) {
			font-size: 10px;
		}

		@include breakpoint(699px) {
			margin: 0 auto;
			display: block;
			font-size: 12px;
		}

		@include breakpoint(420px) {
			width: 100%;
		}
	}
}