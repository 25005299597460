.form {
	display: inline-block;
	margin: 0 auto;
	text-align: left;

	&-item {
		min-width: 300px;

		input[type="text"] {
			width: 100%;
		}
	}

	label, .label {
		display: block;
		margin-bottom: 5px;
	}

	input[type="radio"] + label {
		display: inline-block;
		margin-right: 10px;
		line-height: 1.5rem;
	}

	input[type="checkbox"] + label {
		display: inline-block;
		margin-right: 10px;
		line-height: 1.5rem;
	}
}