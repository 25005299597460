.about {

	@include breakpoint(1200) {
		font-size: 11px;
	}

	.heading-description {
		margin-bottom: em(37);

		@include breakpoint(1200px) {
			margin-bottom: em(20);
		}
	}

	&-description {
		font-size: em(20);
		line-height: em(22px, 14px);
		text-align: justify;

		& + .about-description {
			margin-top: em(15,14);
		}
	}

	&-text {
		flex: 0 0 40%;
		max-width: 40%;

		@include breakpoint(800px) {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	&-graphic {
		position: relative;
		right: em(-80);
		@include flexbox();
		@include flex_util(middle);
		flex: 0 0 55%;
		max-width: 55%;

		@include breakpoint(1440) {
			font-size: 10px;
		}

		@include breakpoint(1200px) {
			right: 0;
		}

		@include breakpoint(800px) {
			height: 400px;
			margin-top: 60px;
			flex: 0 0 100%;
			max-width: 100%;
		}

		@include breakpoint(600px) {
			height: 300px;
		}

		.about-image {
			background: url("../images/img_about.png") right no-repeat;
			background-size: cover;
			position: relative;
			z-index: 1;
			width: em(800);
			height: em(550);

			@include breakpoint(1200px) {
				height: 100%;
				background-position: center;
			}

			@include breakpoint(800px) {
				height: 100%;
				width: 100%;
				background-position: center 40%;
			}
		}
	}

	.numbers {
		margin-top: em(96);

		@include breakpoint(1000px) {
			font-size: 9px;
		}

		@include breakpoint(700px) {
			font-size: 7px;
		}

		&-col {
			text-align: center;
			position: relative;

			@include breakpoint(600px) {
				flex: 0 0 50%;
				max-width: 50%;
				padding-top: 20px;
				margin-bottom: 20px;
			}

			&._projects:after,
			&._awards:after {

				@include breakpoint(600px) {
					content: '';
					display: block;
					height: 80px;
					width: 1px;
					background-color: #EAEAEA;
					position: absolute;
					top: 28px;
					right: 0;
				}
			}

			&._awards:before,
			&._offices:before {

				@include breakpoint(600px) {
					content: '';
					display: block;
					height: 1px;
					width: 80%;
					background-color: #EAEAEA;
					position: absolute;
					top: 5px;
					left: 10%;
				}
			}
		}

		&-split {
			color: #ACADAF;
			font-size: em(24);

			@include breakpoint(600px) {
				display: none;
			}
		}

		&-amount {
			font-size: em(104);
			font-weight: 500;
			line-height: em(133,104);
		}

		&-projects {
			font-size: em(18);
			line-height: em(16);
			color: #222222;
			opacity: .5;
			margin-top: em(-12,18);

			@include breakpoint(700px) {
				font-size: 15px;
				margin-top: 0;
				text-align: center;
			}
		}
	}

	.btn-container {
		padding-top: em(76);
	}
}