.offices {
	//padding-top: em(200);
	//padding-bottom: em(100);

	@include breakpoint(1100px) {
		//padding-top: em(140);
		//padding-bottom: em(140);
		font-size: 10px;
	}

	.heading {
		line-height: em(48,40);
		margin-bottom: em(80,40);
	}

	&-list {
		position: relative;

		@include breakpoint(425px) {
			font-size: 14px;
		}

		&:last-of-type:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: em(-100);
			width: calc(100% - 30px);
			margin-left: 15px;
			height: 1px;
			background-color: #EAEAEA;

			@include breakpoint(1200px) {
				bottom: -40px;
			}

			@include breakpoint(600px) {
				bottom: 10px;
			}
		}

		[class*="col-"] {
			margin-bottom: em(64);

			@include breakpoint(425px) {
				max-width: 100%;
				flex: 0 0 100%;
			}
		}
	}

	&-location {
		font-size: em(22);
		line-height: em(32,22);
		color: #222222;
		position: relative;
		display: inline-block;

		&:before {
			content: '';
			display: block;
			width: em(29,22);
			//height: em(1,22);
			height: 1px;
			background-color: #222222;
			position: absolute;
			right: em(-60,22);
			top: 50%;
			margin-top: em(-1,22);

			@include breakpoint(1100px) {
				height: 1px;
			}
		}
		
		&:after {
			content: '';
			background-image: url("../images/location.svg");
			background-repeat: no-repeat;
			background-size: contain;
			display: block;
			height: em(19,22);
			width: em(16,22);
			position: absolute;
			right: em(-90,22);
			top: 50%;
			margin-top: em(-10,22);
		}
	}

	&-address {
		font-size: em(16);
		line-height: em(24,16);
		color: #9EA0A6;
	}

	._hide-600 {

		@include breakpoint(600px) {
			display: none;
		}
	}
}