.slogan {
	width: 280px;
	height: 139px;

	@include breakpoint(600px) {
		width: auto;
		height: auto;
	}

	img {

		@include breakpoint(600px) {
			display: none;
		}
	}

	&-text {
		display: none;

		@include breakpoint(600px) {
			font-size: 27px;
			line-height: 32px;
			color: #222222;
			display: block;
			font-weight: 500;
		}

		@include breakpoint(425px) {
			text-align: center;
		}
	}
}

.footer {
	//padding-top: em(145);

	&.container {

		@include breakpoint(600px) {
			padding-left: 0;
			padding-right: 0;
			padding-top: 56px;
		}
	}

	&-top {

		@include breakpoint(600px) {
			padding-left: 20px;
			padding-right: 20px;
			margin: 0;
		}

		.contact {
			@include breakpoint(600px) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.form {
		margin-top: em(48);
		position: relative;

		@include breakpoint(600px) {
			margin-top: 24px;
		}

		@include breakpoint(425px) {
			width: 100%;
		}
		
		&-send {
			position: absolute;
			right: 0;
			top: 0;
			padding-left: em(22,16);
			padding-right: em(22,16);
			height: 100%;
			cursor: pointer;
			background-color: transparent;
			border: none;
			@include transition(background-color);
			@include flexbox();
			@include flex_util(middle);

			@include breakpoint(425px) {
				padding: 20px;
			}

			&:hover {
				background-color: rgba(218,20,17,0.8);

				@include breakpoint(699px) {
					background-color: transparent;
				}
			}

			&:focus {
				outline: none;
			}

			img {
				width: em(24);
				height: em(24);
			}
		}

		&-item {
			position: relative;

			@include breakpoint(425px) {
				width: 100%;
				min-width: 0;
			}
		}

		input[type="text"] {
			font-size: em(16);
			padding: em(21,16);
			padding-right: em(66);
			height: 100%;
			width: em(403,16);
			border: 1px solid #EAEAEA;
			-webkit-appearance: none;
			border-radius: 0;

			@include breakpoint(425px) {
				width: 100%;
				line-height: 24px;
			}
		}

		.example {
			color: #9EA0A6;
			margin-top: em(8,16);
		}
	}

	&-links {

		@include breakpoint(600px) {
			padding-left: 0;
			padding-right: 0;
			font-size: 14px;
		}
	}

	&-link {
		display: inline-block;
		margin-bottom: em(10px);
		color: #222222;
		@include transition(color,0.1s);

		&:hover {
			color: $main_color_red;
			text-decoration: none;
		}

		&-block {
			@include breakpoint(800px) {
				margin-top: 20px;
			}
		}
	}

	&-social {
		text-align: right;

		@include breakpoint(600px) {
			padding-top: 40px;
			padding-bottom: 40px;
			text-align: center;
		}

		&-link {
			margin-left: em(32);
			opacity: .6;

			&:hover {
				opacity: 1;
				@include transition(opacity);
				text-decoration: none;
			}
		}
	}

	&-bottom {
		height: em(104);
		@include flexbox();
		@include flex_util(middle,between);

		@include breakpoint(600px) {
			margin-left: 0;
			margin-right: 0;
			height: auto;
		}

		.copyright {
			font-size: em(13);
			color: #777777;
			line-height: em(24,13);

			@include breakpoint(600px) {
				flex: 0 0 100%;
				order: 2;
				text-align: center;
				background-color: rgba(234,234,234,0.4);
				box-shadow: inset 0 1px 0 0 #EAEAEA;
				font-size: 12px;
				color: #9EA0A6;
				line-height: 24px;
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}
	}
}

.footer-divider {
	position: relative;
	margin-top: em(80);

	@include breakpoint(600px) {
		margin-left: 0;
		margin-right: 0;
	}

	.col {
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: calc(100% - 30px);
			margin-left: 15px;
			height: 1px;
			background-color: #EAEAEA;
		}
	}
}

.message-sent {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 10;
	display: block;
	width: 160px;
	height: 160px;
	border-radius: 5px;
	margin-left: -80px;
	margin-top: -80px;
	background: #28a745;
	color: #ffffff;
	font-size: 20px;
	padding-top: 120px;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.7s ease-out;

	&:before {
		content: '';
		background: url("../images/send.svg") center no-repeat;
		background-size: contain;
		width: 70px;
		height: 70px;
		display: block;
		position: absolute;
		left: 50%;
		top: 30px;
		margin-left: -35px;
	}

	&.show {
		opacity: 0.7;
		visibility: visible;
	}
}