.header {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	z-index: 3;
	box-shadow: inset 0 -1px 0 0 rgba(255,255,255,0.4);
	background-color: transparent;
	@include transition(background-color, 0.2s);

	@include breakpoint(1200) {
		font-size: 14px;
	}

	@include breakpoint(1000px) {
		font-size: 12px;
	}

	@include breakpoint(699px) {
		height: 72px;
	}

	&.menu-open {

		@include breakpoint(699px) {
			background-color: #ffffff;
			box-shadow: inset 0 -1px 0 0 rgba(255,255,255,1);
		}
	}

	// =================================
	// State when menu is floating
	// =================================

	&.fixed {
		position: fixed;
		background-color: #ffffff;
		box-shadow: 0 em(13) em(13) 0 rgba(0,0,0,0.08);
		transform: translate(0,-88px);

		&.menu-hide {
			opacity: 0;
			transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		}

		&.menu-show {
			transform: translate(0,0);
			opacity: 1;
			transition: transform 0.2s ease-out, opacity 0.2s ease-out;

			.menu-toggle {

				&:before,
				&:after {
					background-color: #222222;
				}
			}
		}

		.landing-navigation .menu-link {
			@include minBreakpoint(700px) {
				color: #222222;
			}

			&:hover:before {
				opacity: 0.3;
			}
		}

		.languages {
			color: #222222;

			&:after {
				border-top-color: #222222;
			}

			&:hover,
			&.lang-open,
			.lang-list {
				border-color: #EAEAEA;
			}

			.lang-list {
				border-top-color: transparent;
			}

			&.lang-open {
				border-bottom-color: transparent;
			}

			.lang-list a {
				color: #222222;

				&:hover {
					background-color: rgba(0,0,0,0.1);
				}
			}
		}

		.logo-menu-white {
			opacity: 0;
		}

		.logo-menu {
			opacity: 1;
		}
	}
}

// =================================
// Global Navigation
// =================================

.landing-navigation {
	height: em(72px);
	position: relative;
	@include flexbox();
	@include flex_util(between,middle);

	@include breakpoint(1100px) {
		padding-left: 4vw;
		padding-right: 4vw;
	}

	@include breakpoint(799px) {
		max-width: 100%;
	}

	@include breakpoint(699px) {
		position: absolute;
		top: 71px;
		left: 0;
		background: #ffffff;
		height: auto;
		box-shadow: 0 8px 13px 0 rgba(0,0,0,0.08);
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-out, visibility .1s linear;
	}

	@include breakpoint(480) {
		position: fixed;
		height: 100vh;
		display: flex;
		align-items: flex-start;
	}

	.menu-open & {

		@include breakpoint(699px) {
			opacity: 1;
			visibility: visible;
		}
	}

	ul {
		padding: 0;
		list-style-type: none;
		display: inline-block;
		margin: 0;
		height: 100%;

		@include breakpoint(699px) {
			margin-top: 26px;
		}

		li {
			display: inline-block;
			height: 100%;

			@include breakpoint(699px) {
				display: block;
				margin-bottom: 42px;
				padding-left: 8px;
			}
		}
	}

	.menu-link {
		color: #ffffff;
		margin-right: em(48);
		font-size: em(15);
		font-weight: 500;
		padding: 0;
		position: relative;
		display: inline-block;
		min-width: 0;
		height: 100%;
		line-height: em(72,15);

		@include breakpoint(699px) {
			color: #222222;
			font-size: 18px;
			@include transition(color,.2s);
		}

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: em(3);
			background-color: $main_color_red;
			position: absolute;
			left: 0;
			bottom: 0;
			opacity: 0;
			@include transition(opacity);

			@include breakpoint(699px) {
				content: none;
			}
		}

		&:hover:before {
			opacity: 0.8;
		}

		&.active:before {
			opacity: 1 !important;
		}

		&.active {

			@include breakpoint(699px) {
				color: $main_color_red;
			}
		}
	}

	// =================================
	// Language switch
	// =================================

	.languages {
		position: relative;
		font-size: 12px;
		line-height: 24px;
		text-transform: uppercase;
		font-weight: 500;
		padding: em(5) em(15) em(3);
		background: transparent;
		transition: background 0.2s ease-out, color 0.2s ease-out;
		cursor: pointer;
		color: #ffffff;
		width: em(160);
		border: 1px solid rgba(255,255,255,0.2);
		display: none !important; // Temporary - remove when add more languages

		@include breakpoint(699px) {
			color: #222222;
			position: absolute;
			right: 17px;
			top: -53px;
			border: 1px solid rgba(34,34,34,0.1);
			opacity: 0;
			visibility: hidden;
			transition: opacity .2s ease-out, visibility .1s linear;
		}

		.menu-open & {
			@include breakpoint(699px) {
				opacity: 1;
				visibility: visible;
			}
		}

		&:hover {
			background: rgba(255,255,255,1);
			color: #222222;
		}

		&.lang-open {
			background: rgba(255,255,255,1);
			color: #222222;

			.lang-list {
				opacity: 1;
				visibility: visible;
				transform: translate(0,0) scale(1);
			}
		}

		&.lang-close {

			.lang-list {
				opacity: 0;
				transform: translate(0,-15px) scale(0.8);
				visibility: hidden;
				transition: opacity 0.1s ease-out, transform 0.2s linear, visibility 0.1s linear 0.3s;
			}
		}

		img {
			margin-right: em(4);
			margin-top: em(-4);
		}

		&:after {
			content: '';
			display: inline-block;
			width: em(8);
			height: em(8);
			border-left: em(1) solid #EAEAEA;
			border-bottom: em(1) solid #EAEAEA;
			transform: rotate(-45deg);
			position: absolute;
			top: 50%;
			right: em(12);
			margin-top: em(-8);
		}

		.lang-list {
			width: em(160);
			position: absolute;
			left: -1px;
			top: 100%;
			font-size: 12px;
			background: #ffffff;
			transition: opacity 0.3s ease-out, transform 0.1s linear;
			border: 1px solid rgba(255, 255, 255, 0.2);
		}

		a {
			display: block;
			padding-left: em(15);
			padding-right: em(15);
			width: 100%;
			color: #000000;
			cursor: pointer;
			line-height: em(35);
			transition: background-color 0.2s ease-out;
			text-transform: uppercase;
			font-weight: 400;

			&:hover, &.active {
				text-decoration: none;
				background-color: rgba(0,0,0,0.2);
			}
		}
	}
}

// =================================
// Back to top button
// =================================

.back-to-top {
	position: absolute;
	bottom: -70vh;
	right: em(-35,22);
	opacity: 0;
	z-index: 100;
	width: em(60,22);
	height: em(60,22);
	background-color: rgba(234,234,234,0.6);
	background-size: 30%;
	border-radius: 50%;
	transition: opacity 0.3s ease-out, visibility 0.1s linear 0.3s;
	line-height: em(35);
	text-align: center;
	font-size: em(22);
	color: #212529 !important;
	padding: 0;
	visibility: hidden;

	@include breakpoint(1550px) {
		right: 1%;
	}

	@include breakpoint(1200) {
		right: em(10,22);
		font-size: 14px;
	}

	@include breakpoint(1100px) {
		right: em(30);
	}

	&.show {
		opacity: 0.8;
		transition: opacity 0.3s ease-out, visibility 0.1s linear;
		visibility: visible;

		&:hover {
			opacity: 1;
		}
	}
}

// =================================
// Mobile menu toggle button
// =================================

.menu-toggle {
	height: 100%;
	width: 72px;
	position: relative;

	@include minBreakpoint(700px) {
		display: none;
	}

	&:before,
	&:after {
		content: '';
		display: block;
		height: 2px;
		width: 24px;
		background-color: #fff;
		position: absolute;
		left: 50%;
		margin-left: -12px;
		top: 50%;
		@include transition(all,0.2s);
	}

	&:before {
		margin-top: -8px;
	}

	&:after {
		margin-bottom: -8px;
	}

	.menu-open & {

		&:before {
			transform: rotate(45deg) translate(2px, 4px);
			background-color: #222222;
		}

		&:after {
			transform: rotate(-45deg) translate(2px, -4px);
			background-color: #222222;
		}
	}
}

// =================================
// Logo shown on mobile menu only
// =================================

.logo-menu-white {
	background: url("../images/logo_menu_white.png") center no-repeat;
	background-size: contain;
	width: 60px;
	height: 76px;
	position: absolute;
	right: 21px;
	top: 35px;
	opacity: 1;
	@include transition(opacity, .2s);

	.menu-open & {
		opacity: 0 !important;
		transition: none;
	}
}

.logo-menu {
	background: url("../images/logo_menu.png") center no-repeat;
	background-size: contain;
	width: 42px;
	height: 53px;
	position: absolute;
	right: 21px;
	top: 10px;
	opacity: 0;
	@include transition(opacity, .2s);

	.menu-open & {
		opacity: 0 !important;
	}
}

.logo-menu-white,
.logo-menu {

	@include minBreakpoint(700px) {
		display: none;
	}
}

.nav-link-hide {
	// Hide elements from menu on desktop

	@include minBreakpoint(700px) {
		display: none;
	}
}