html {
	font-size: 14px;

	@include resize_screen(1600px,1401px) {
		font-size: 13px;
	}

	@include breakpoint(1680px) {
		font-size: 12px;
	}

	//@include minBreakpoint(1800px) {
	//	font-size: 24px;
	//}
}

section {
	//width: 100%;
	//padding: $section_padding_vertical $section_padding_horizontal;
}

.common-space-top {
	padding-top: em(200);

	@include breakpoint(1200px) {
		padding-top: em(160);
	}

	@include breakpoint(1000px) {
		padding-top: em(120);
	}

	@include breakpoint(699px) {
		padding-top: em(90);
	}

	@include breakpoint(425px) {
		padding-top: em(56);
	}
}

.common-space-bottom {
	padding-bottom: em(200);

	@include breakpoint(1200px) {
		padding-bottom: em(160);
	}

	@include breakpoint(1000px) {
		padding-bottom: em(120);
	}

	@include breakpoint(699px) {
		padding-bottom: em(90);
	}

	@include breakpoint(425px) {
		padding-bottom: em(56);
	}
}

.container-fluid {
	padding-left: 0;
	padding-right: 0;

	.container {
		@include minBreakpoint(1000px) {
			padding-left: 5%;
			padding-right: 5%;
		}
	}
}

.container {
	@include breakpoint(699px) {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.container._narrow {

	@include minBreakpoint(1000px) {
		padding-left: em(60);
		padding-right: em(60);
	}

	@include minBreakpoint(1280px) {
		padding-left: em(115);
		padding-right: em(115);
	}
}

.heading {
	font-size: em(42);
}

.heading-description {
	opacity: 0.3;
	font-size: em(30);
	font-weight: 400;
	line-height: em(20);
}

._text-left {
	text-align: left;
}

._text-center {
	text-align: center;
}

._text-right {
	text-align: right;
}