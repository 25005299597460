.calculate {
	background-image: url("../images/calc_img_bottom.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding-top: em(230);
	padding-bottom: em(240);

	@include minBreakpoint(3500px) {
		background-image: url("../images/calc_img_bottom_5k.jpg");
	}

	@include breakpoint(1300px) {
		background-image: url("../images/calc_img_bottom_1300.jpg");
		background-size: cover;
	}

	@include breakpoint(1100px) {
		padding-top: em(120);
		padding-bottom: em(120);
		background-size: cover;
		font-size: 10px;
	}

	@include breakpoint(600px) {
		background-position: 60%;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.2);
	}

	.container {
		position: relative;
		z-index: 1;
	}

	.heading {
		font-size: em(60);
		color: #ffffff;
		margin-bottom: em(28,60);

		&-description {
			font-size: em(30);
			line-height: em(40,30);
			color: #ffffff;
			opacity: .6;
			width: em(690,30);
			margin-bottom: em(80,30);

			@include breakpoint(600px) {
				width: 100%;
			}
		}
	}

	.btn {

		&-container, & {

			@include breakpoint(425px) {
				width: 100%;
			}
		}

		@include breakpoint(600px) {
			display: block;
			margin: 0 auto;
		}

		& + .btn {
			margin-left: em(24);

			@include breakpoint(600px) {
				margin-left: auto;
				margin-top: 16px;
			}
		}
	}
}