.faq {
	//padding-top: em(200);
	//padding-bottom: em(200);

	@include breakpoint(1100px) {
		//padding-top: em(140);
		//padding-bottom: em(140);
		font-size: 10px;
	}

	.container {

		@include breakpoint(600px) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.heading {
		font-weight: 500;

		@include breakpoint(600px) {
			padding-left: 30px;
			padding-right: 30px;
		}

		&-description {
			margin-bottom: em(48,40);

			@include breakpoint(600px) {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}

	h5 {
		font-size: 1rem;
	}
}

.card {
	border-left: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;

	&:first-of-type {
		border-top: 0;
		border-left: 0;
		border-right: 0;
	}

	&:last-of-type {
		border-bottom: 0;
	}

	&-header {
		background: none;
		border: none;
		padding: 0;
	}

	&-body {
		font-size: em(18);
		padding: em(32) em(77);
	}

	[aria-selected*="true"] .btn-faq {
		background-color: rgba(0,0,0,0);

		&:after {
			transform: rotate(135deg);
		}
	}

	.btn-faq {
		font-size: em(20);
		line-height: em(32,20);
		padding: em(32,20) em(77,20);
		color: #222222;
		width: 100%;
		text-align: left;
		position: relative;

		@include breakpoint(1100px) {
			font-size: 17px;
		}

		@include breakpoint(425px) {
			white-space: normal;
		}



		&:hover {
			background-color: rgba(0,0,0,0.02);
		}

		&:before {
			content: '';
			display: block;
			width: em(29,20);
			height: 2px;
			background-color: #222222;
			position: absolute;
			top: 50%;
			left: em(30,20);
			margin-top: em(-1,20);

			@include breakpoint(1100px) {
				height: 1px;
			}

			@include breakpoint(600px) {
				width: 20px;
			}
		}

		&:after {
			content: '';
			background-image: url("../images/plus.svg");
			background-repeat: no-repeat;
			background-size: contain;
			display: block;
			width: em(24,20);
			height: em(24,20);
			position: absolute;
			top: 50%;
			right: em(30,20);
			margin-top: em(-12,20);
			transform: rotate(0);
			@include transition(transform, 0.4s);

			@include breakpoint(600px) {
				width: 18px;
				height: 18px;
			}
		}
	}
}