@import "variables";
@import "libs/bootstrap/bootstrap.scss";

@import "../fonts/fonts.css";

@import "mixins";
@import "normalize";

@import "buttons";
@import "inputs";
@import "navigation";

@import "main";
@import "about";
@import "services";
@import "projects";
@import "faq";
@import "calculate";
@import "offices";
@import "footer";
@import "global";
