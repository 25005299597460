.projects {
	//padding-top: em(200);
	//padding-bottom: 0;

	@include breakpoint(1100px) {
		//padding-top: em(140);
		font-size: 9px;
	}

	&-slider {
		height: em(900);
		margin-top: em(80);

		@include breakpoint(900px) {
			height: em(780);
		}

		@include breakpoint(699px) {
			height: em(800);
		}
	}

	&-steps {

		.col {
			width: em(330);
			flex: 0 0 em(330);

			@include breakpoint(700px) {
				width: 60%;
				flex: 0 0 60%;
				margin: 0 auto 50px;
			}

			@include breakpoint(550px) {
				width: 100%;
				flex: 0 0 100%;
			}
		}

		&-container {
			padding-top: em(200);
			padding-bottom: em(200);

			@include breakpoint(1100px) {
				padding-top: em(140);
				padding-bottom: em(140);
			}

			.heading {
				line-height: em(48,40);

				&-description {
					margin-bottom: em(80,40);
				}
			}
		}

		&-number {
			font-size: em(104);
			line-height: em(125,104);
			margin-bottom: em(70,104);
			color: #9EA0A6;
			opacity: 0.2;
			font-weight: 500;
			position: relative;

			@include breakpoint(700px) {
				margin-bottom: 30px;
			}

			&:after {
				content: '';
				width: 80%;
				height: 1px;
				background-color: #EAEAEA;
				position: absolute;
				bottom: -40px;
				left: 0;

				@include breakpoint(700px) {
					bottom: -10px;
				}

				@include breakpoint(550px) {
					width: 100%;
				}
			}
		}

		&-title {
			font-size: em(26);
			line-height: em(32,26);
			margin-bottom: em(24,26);
		}

		&-description {
			color: #222222;
			opacity: 0.4;
			font-size: em(22);
			line-height: em(28,22);

			@include breakpoint(700px) {
				font-size: 14px;
			}
		}
	}

	&-video-container {
		padding-bottom: em(200);

		@include breakpoint(1100px) {
			padding-bottom: em(140);
		}

		.heading {
			font-size: em(40);
			line-height: em(48,40);
		}
	}

	&-video {
		margin-top: em(68);

		@include breakpoint(768px) {
			height: 300px;
			white-space: nowrap;
			display: block;
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;
			margin-left: -30px;
			margin-right: -30px;
		}

		&-file {
			margin-bottom: em(45);

			@include breakpoint(768px) {
				display: inline-block;
				height: 65%;
				width: 55%;
				min-width: 300px;
				flex: none;
				max-width: none;
			}

			&._hidden {
				@include minBreakpoint(769px) {
					display: none;
				}
			}

			//@include breakpoint(425px) {
			//	max-width: 100%;
			//	flex: 0 0 100%;
			//}

			iframe {
				width: 100%;
				height: 16vw;

				@include breakpoint(800px) {
					height: 28vw;
				}

				@include breakpoint(768px) {
					height: 100%;
				}
			}
		}

		.delta-ukraine {
			color: #9EA0A6;
			font-size: em(18);
			line-height: em(24,18);
			font-weight: 500;
			margin-top: em(20,18);

			@include breakpoint(768px) {
				font-size: 14px;
			}
		}

		&-title {
			color: #222222;
			font-size: em(20);
			line-height: em(32,20);

			@include breakpoint(768px) {
				font-size: 16px;
			}
		}
	}

	.btn-container {
		margin-top: em(80);

		@include breakpoint(600px) {
			margin-top: em(40);
		}
	}

	.btn-more {
		color: #222222;
		font-size: em(14);
		line-height: em(24);
		padding: em(10) em(20) em(7);
		margin-bottom: em(60);

		&:hover {
			background-color: rgba(0,0,0,0.1);
		}

		@include breakpoint(768px) {
			display: none;
		}

		//&:before {
		//	content: '';
		//	display: inline-block;
		//	width: em(19);
		//	height: em(19);
		//	margin-right: em(10);
		//	vertical-align: em(-4);
		//	background-image: url("../images/refresh.svg");
		//	background-repeat: no-repeat;
		//	background-size: contain;
		//}
	}

	//
	// Bottom Images
	//

	&-bottom {
		margin-left: 0;
		margin-right: 0;

		&-image {
			display: block;
			height: em(385);
			background-repeat: no-repeat;
			background-size: cover;

			&._image-1 {
				background-image: url('../images/projects/projects_bottom_1.png');
			}

			&._image-2 {
				background-image: url('../images/projects/projects_bottom_2.png');
			}

			&._image-3 {
				background-image: url('../images/projects/projects_bottom_3.png');
			}

		}
	}
}

//
// 	SWIPER - slider for projects overview
//	settings for slider elements and image
//

.swiper {

	&-container {
		width: 100%;
		height: 100%;
	}

	&-slide {
		background-position: center;
		background-size: cover;

		@include flexbox();

		&._slide-bmw .swiper-image {
			background-image:url("../images/projects/bmw.jpg");
		}

		&._slide-leoni .swiper-image {
			background-image:url("../images/projects/leoni.jpg");

			@include breakpoint(900px) {
				background-position: center;
			}
		}

		&._slide-ave .swiper-image {
			background-image:url("../images/projects/ave.jpg");

			@include breakpoint(900px) {
				background-position: 26%;
			}
		}

		&._slide-billa .swiper-image {
			background-image:url("../images/projects/billa.jpg");

			@include breakpoint(900px) {
				background-position: 18%;
			}
		}

		&._slide-it .swiper-image {
			background-image:url("../images/projects/tobacco.jpg");
		}

		&._slide-kws .swiper-image {
			background-image:url("../images/projects/kws.png");
		}

		&._slide-ekom .swiper-image {
			background-image:url("../images/projects/ekom.jpg");
		}

		&._slide-ifa .swiper-image {
			background-image:url("../images/projects/ifa.jpg");
		}

		&._slide-erber .swiper-image {
			background-image:url("../images/projects/erber.jpg");
		}

		&._slide-multikraft .swiper-image {
			background-image:url("../images/projects/multikraft.jpg");
		}
		
		@include breakpoint(600px) {
			overflow: hidden;
		}
	}

	&-image {
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		width: 58%;

		@include breakpoint(900px) {
			width: 50%;
		}

		@include breakpoint(600px) {
			width: 100%;
			flex: 0 0 100%;
		}
	}

	&-description {
		width: 42%;
		background-color: #fff;

		@include breakpoint(900px) {
			width: 50%;
		}

		@include breakpoint(600px) {
			width: 100%;
			flex: 0 0 100%;
			position: absolute;
			left: 0;
			top: 75%;
			opacity: 0.9;
			height: 100%;
			transform: translate(0,0);
			@include transition(transform, 0.3s);
		}

		&.description-open {

			@include breakpoint(600px) {
				transform: translate(0,-75%);
			}
		}
	}

	&-container {

		.swiper-button-next,
		.swiper-button-prev {
			top: auto;
			bottom: em(33,22);
			margin-top: 0;
			width: em(60,22);
			height: em(60,22);
			background-color: rgb(255,255,255);
			background-size: 30%;
			border-radius: 50%;
			opacity: 0.7;
			transition: opacity 0.3s ease-out;
			line-height: em(58,22);
			text-align: center;
			font-size: em(22);
			background-image: none;

			@include breakpoint(600px) {
				top: em(33,22);
				bottom: auto;
				box-shadow: 0 em(5) em(5) 0 rgba(0,0,0,0.08);
			}

			&:hover {
				opacity: 1;
			}

			&:before {
				content: '\2190';
			}
		}

		.swiper-button-disabled {
			opacity: 0.3;
		}

		.swiper-button-next {
			right: 47%;
			margin-right: em(-40);

			&:before {
				content: '\2192';
			}

			@include breakpoint(900px) {
				right: 58%;
			}

			@include breakpoint(600px) {
				right: em(30,22);
				margin-right: 0;
			}
		}

		.swiper-button-prev {
			left: 52.6%;
			margin-left: em(-40);

			@include breakpoint(900px) {
				left: 41%;
			}

			@include breakpoint(600px) {
				right: em(110,22);
				left: auto;
				margin-right: 0;
			}
		}
	}


	&-container-horizontal > &-pagination-bullets {
		width: 58% !important;

		@include breakpoint(900px) {
			width: 50% !important;;
		}

		@include breakpoint(600px) {
			display: none;
		}
	}
}

//
// 	SWIPER - slider for projects overview
//	settings for text part of slider
//

.project {
	padding: em(80) em(110);
	position: relative;
	height: 100%;

	@include breakpoint(1100px) {
		font-size: 9px;
		padding: em(100) em(90);
	}

	@include breakpoint(900px) {
		padding: 30px 40px;
	}

	@include breakpoint(800px) {
		padding: 12px 30px;
	}

	@include breakpoint(600px) {
		padding: 40px 30px;
	}

	&:before {

		@include breakpoint(600px) {
			content: '';
			width: 78px;
			height: 5px;
			border-radius: 2.5px;
			background-color: #222222;
			position: absolute;
			left: 50%;
			margin-left: -39px;
			top: 11px;
		}
	}

	&-name {
		font-size: em(40);
		line-height: em(32,40);
		margin-bottom: em(32,40);

		@include breakpoint(800px) {
			font-size: em(30);
		}
	}

	&-title {
		color: #777777;
		font-size: em(20);
		line-height: em(24,20);
		margin-bottom: em(8,20);
	}

	&-caption {
		color: #222222;
		font-size: em(20);
		line-height: em(32,20);
		margin-bottom: em(32,20);
	}

	&-services {
		color: #222222;
		font-size: em(20);
		line-height: em(24,20);

		ul {
			list-style-type: none;
			padding-left: 0;

			li {
				line-height: em(38,20);

				&:before {
					content: '\25AA';
					color: $main_color_red;
					font-size: em(20);
					position: relative;
					top: 2px;
					margin-right: em(12,20);
				}
			}
		}
	}

	&-info {
		position: absolute;
		bottom: em(40);
		left: 0;
		padding: 0 em(110);
		margin: 0;
		width: 100%;

		@include breakpoint(1000px) {
			font-size: 6px;
		}

		@include breakpoint(700px) {
			font-size: 5px;
			bottom: em(60);
		}
	}

	&-year,
	&-area {
		font-size: em(62);
		line-height: em(77,64);
		font-weight: 500;

		@include breakpoint(1280px) {
			font-size: em(50);
		}

		@include breakpoint(1000px) {
			font-size: em(62);
		}
	}

	&-area {
		position: relative;
		display: inline-block;

		&:after {
			content: 'm2';
			font-size: em(24,64);
			vertical-align: em(28,24);
			line-height: em(24,24);
			position: absolute;
			left: 100%;
			top: 0;
		}
	}

	&-subtitle {
		color: #777777;
		font-size: em(18);
		line-height: em(16,18);

		@include breakpoint(1000px) {
			font-size: em(40);
		}
	}
}